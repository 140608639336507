import { PageElement, html, css } from 'Elements';
import styles from './styles.css.js';
import 'Components/business/select-jobs.js';

class SelectJobsPage extends PageElement {

  static get styles() {
    return [
      super.styles,
      styles,
      css`
        .flex {
          display:flex;
          align-items:center;
          gap:20px;
          margin-bottom:20px;
          width:100%;
        }

        b {
          font-size:2em;
          height:40px;
        }

        select-jobs {
          flex:1;
        }
      `
    ]
  }

  static get properties() {
    return {
      value: { type: String },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.value = 'pdg,rsi';
  }

  async updated(changedProperties) {
    if (changedProperties.has('value')) {
      //this._log.debug('updated: value:', this.value);
    }
  }

  onChange(ev) {
    this.qs('span').innerText = ev.detail;
  }

  render() {

    return html`
      <section-header backroute="../" micon="developer_board">Composants UX</section-header>
      <div class="title">
        <h3>&lt;select-jobs&gt;</h3>
        <div>Un selecteur de rôle</div>
      </div>
      <sl-tab-group>  
        <sl-tab slot="nav" panel="component">Composant</sl-tab>
        <sl-tab-panel name="component">
          <div class="flex">
            <select-jobs value=${this.value} @change=${this.onChange}></select-jobs>
            <div style="flex:0.5">Valeur: <span></span></div>
          </div>
        </sl-tab-panel>
      </sl-tab-group>
      <br/><br/><br/><br/>
    `;
  }
}

customElements.define('page-tests-select-jobs', SelectJobsPage);